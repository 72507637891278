<template>
	<div :class="'flight-listbox ' +(type=='酒店'?'bggray':'')">
		<div :class="'feihuoLoading ' +(type=='酒店'?'bggray':'')" v-if="type!='航班'">
			<div class="serch-loading-img">
				<img src="../assets/serch_loading.gif" style="width:140px"/>
			</div>
			<p class="mt20 ft16">正在搜索...</p>
		</div>
		<div v-else class="feihuoLoading">
			<img src="https://www.yiqifei.com/Content/images/nocall_logo.gif" class="img">
			<p class="mt5 ft16">正在为您搜索航班信息…</p>
			<img src="https://www.yiqifei.com/Content/images/loading/new_loading.gif" class="img">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FlightLoading',
		props: ['msg','type'],
		data() {
			return {}
		}
	}
</script>

<style>
	.bggray{
		 background: #eee;
	}
</style>
